import "./cvEntry.css"
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import React from "react";

class CVEntry extends React.Component {
    render() {
        return (
            <TimelineItem>
                <TimelineOppositeContent className={"timelineDate"}>
                    {this.props.info.date}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot/>
                    <TimelineConnector/>
                </TimelineSeparator>
                <TimelineContent>
                    <a href={this.props.info.url} className={"timelineContentWrapper"} >
                        <div className={"timelineContent"}>
                            <h3>
                                {this.props.info.location}
                            </h3>
                            <div>
                                {this.props.info.description}
                            </div>
                        </div>
                    </a>

                </TimelineContent>
            </TimelineItem>
        )
    }
}

export default CVEntry;