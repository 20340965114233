import "./cv.css"
import Timeline from '@material-ui/lab/Timeline';
import CVEntry from "./cvEntry";

const cvEntries = [
    {
        date: "April 2018",
        location: "Max Planck Gymnasium, Saarlouis",
        url: "https://www.mpg-saarlouis.de",
        description: "Finished my A-Levels"
    },
    {
        date: "October 2018",
        location: "Saarland University, Saarbrücken",
        url: "https://www.uni-saarland.de",
        description: "Started my Bachelor studies in Computer Science"
    },
    {
        date: "March 2020",
        location: "inexio GmbH, Saarlouis",
        url: "https://www.inexio.net",
        description: "Started my working student job at inexio"
    },
    {
        date: "June 2020",
        location: "Thola",
        url: "https://github.com/inexio/thola",
        description: "Development on Thola started"
    },
    {
        date: "July 2020",
        location: "Deutsche Glasfaser GmbH, Saarlouis",
        url: "https://www.deutsche-glasfaser.de",
        description: "inexio and Deutsche Glasfaser merged"
    },
]

function CV() {
    return (
        <div className={"cv"}>
            <h1 className={"headingFontSize"}>
                CV
            </h1>
            <div id={"timeline"}>
                <Timeline align="alternate">
                    {cvEntries.map((cvEntry) => <CVEntry info={cvEntry}/>)}
                </Timeline>
            </div>
        </div>
    )
}

export default CV;
