import "./projects.css"
import Project from "./project";

const projects = [
    {
        title: "Thola",
        id: "inexio/thola",
        description: "Thola is a tool for monitoring network devices written in Go. " +
            "It features a check mode which is compatible with Nagios, Icinga, Zabbix, CheckMK and many more."
    },
    {
        title: "Icinga2 Discord Notifications",
        id: "TheFireMike/icinga2-discord-notifications",
        description: "This project provides Discord notification for Icinga2. " +
            "It can be used as a notification plugin command, making it extremely easy to integrate."
    }
]

function Projects() {
    return (
        <div>
            <h1 className={"headingFontSize"}>
                Projects
            </h1>
            <div className={"projects"}>
                {projects.map((project) => <Project info={project}/>)}
            </div>
        </div>
    )
}

export default Projects;