import "./app.css"
import Header from "./header/header";
import Home from "./home/home";
import Imprint from "./imprint/imprint";
import Footer from "./footer/footer";
import NotFound from "./notFound/notFound";
import React from "react";
import {
    BrowserRouter,
    Switch,
    Route,
} from "react-router-dom";

function App() {
    return (
        <div className={"app"}>
            <BrowserRouter>
                <Header/>
                <div id={"content"}>
                    <Switch>
                        <Route exact path="/">
                            <Home/>
                        </Route>
                        <Route exact path="/imprint">
                            <Imprint/>
                        </Route>
                        <Route>
                            <NotFound/>
                        </Route>
                    </Switch>
                </div>
                <Footer/>
            </BrowserRouter>
        </div>
    )
}

export default App;