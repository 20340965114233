import "./intro.css"
import mugshot from "../../../images/mugshot.jpg";

function Intro() {
    return (
        <div className={"intro"}>
            <div>
                <h1 id={"title"}>
                    Hi! I'm <span id={"titleName"}>
                            Mika
                        </span>!
                </h1>
                <h2 id={"subTitle"}>
                    Backend Software Developer in the Network Monitoring Department at <a
                    href={"https://www.deutsche-glasfaser.de"}>
                    Deutsche Glasfaser
                </a>.
                </h2>
            </div>
            <div id={"picture"}>
                <img alt={"mugshot"} id={"mugshot"} src={mugshot}/>
            </div>
        </div>
    )
}

export default Intro;