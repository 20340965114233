import "./notFound.css"

function NotFound() {
    return (
        <div className={"notFound"}>
            <h1 id={"notFoundCode"}>
                404
            </h1>
            <p id={"notFoundMessage"}>
                Page not found
            </p>
        </div>
    );
}

export default NotFound;