import "./contact.css"

function Contact() {
    return (
        <div className={"contact"}>
            <h1 id={"contactHeading"} className={"headingFontSize"}>
                Questions?<br/>Contact me!
            </h1>
            <p id={"contactMessage"}>
                You can reach out to me over at <a href="mailto:hello@mikameyer.net">
                hello@mikameyer.net
            </a>.
            </p>
        </div>
    )
}

export default Contact;