function About() {
    return (
        <div>
            <h1 className={"headingFontSize"}>
                About Me
            </h1>
            <div className={"contentFontSize"}>
                <p>
                    I'm a 21 year old computer science student currently enrolled at <a
                    href={"https://www.uni-saarland.de/start.html"}>
                    Saarland University
                </a> doing my Bachelor of Science. I focus my studies on software engineering, database
                    systems and data networks.
                </p>
                <p>
                    Next to my studies I work at <a href={"https://www.deutsche-glasfaser.de"}>
                    Deutsche Glasfaser
                </a> as a working student in the Network Monitoring department. There I'm working on the Open
                    Source Project <a href={"https://www.github.com/inexio/thola"}>
                    Thola
                </a> and doing maintenance in the monitoring system.
                </p>
                <p>
                    I love developing large scale backends, especially using <a href={"https://golang.org"}>
                    Golang
                </a> as a programming language. Through my studies and work experience, I also gained knowledge
                    in <a href={"https://www.java.com"}>
                    Java
                </a>, <a href={"https://www.cplusplus.com"}>
                    C++
                </a> and <a href={"https://www.php.net"}>
                    PHP
                </a>. I also really enjoy managing the continuous integration and deployment of my projects
                    using <a href={"https://github.com/features/actions"}>
                    GitHub Actions
                </a>, <a href={"https://www.docker.com"}>
                    Docker
                </a> and <a href={"https://www.jenkins.io"}>
                    Jenkins
                </a>.
                </p>
            </div>
        </div>
    )
}

export default About;