import './footer.css';

function Footer() {
    return (
        <div className={"footer"}>
            <div id={"copyright"}>
                © {(new Date().getFullYear())} Mika Meyer
            </div>
            <div id={"center"}>
                |
            </div>
            <div id={"imprint"}>
                <a href={"/imprint"}>
                    Imprint & Privacy Policy
                </a>
            </div>
        </div>
    );
}

export default Footer;