import './home.css';
import React from "react";
import Intro from "./elements/intro"
import About from "./elements/about";
import Projects from "./elements/projects";
import Contact from "./elements/contact";
import CV from "./elements/cv"

function Home() {
    return (
        <div className={"home"}>
            <Intro/>
            <About/>
            <Projects/>
            <CV/>
            <Contact/>
        </div>
    );
}

export default Home;