import "./project.css"
import React from "react";

class Project extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stars: 0,
            forks: 0,
        };
    }

    async componentDidMount() {
        let repo = await fetch("https://api.github.com/repos/" + this.props.info.id)
            .then(response => response.json())
        this.setState({
            stars: repo.stargazers_count,
            forks: repo.forks_count,
        })
    }

    render() {
        return (
            <div className={"project"}>
                <a href={"https://github.com/" + this.props.info.id}>
                    <div className={"projectContent"}>
                        <div className={"projectTitle"}>
                            <h1>
                                {this.props.info.title}
                            </h1>
                        </div>
                        <p className={"projectDescription contentFontSize"}>
                            {this.props.info.description}
                        </p>
                        <div className={"repoInfo"}>
                            <div>
                                <div>
                                    <svg className={"repoIcon"} xmlns="http://www.w3.org/2000/svg" width="24"
                                         height="24"
                                         viewBox="0 0 24 24">
                                        <path
                                            d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/>
                                    </svg>
                                </div>
                                <div>
                                    {this.state.stars}
                                </div>
                            </div>
                            <div>
                                <div>
                                    <svg className={"repoIcon"} xmlns="http://www.w3.org/2000/svg" width="24"
                                         height="24" viewBox="0 0 24 24">
                                        <path
                                            d="M21 3c0-1.657-1.343-3-3-3s-3 1.343-3 3c0 1.323.861 2.433 2.05 2.832.168 4.295-2.021 4.764-4.998 5.391-1.709.36-3.642.775-5.052 2.085v-7.492c1.163-.413 2-1.511 2-2.816 0-1.657-1.343-3-3-3s-3 1.343-3 3c0 1.305.837 2.403 2 2.816v12.367c-1.163.414-2 1.512-2 2.817 0 1.657 1.343 3 3 3s3-1.343 3-3c0-1.295-.824-2.388-1.973-2.808.27-3.922 2.57-4.408 5.437-5.012 3.038-.64 6.774-1.442 6.579-7.377 1.141-.425 1.957-1.514 1.957-2.803zm-16.8 0c0-.993.807-1.8 1.8-1.8s1.8.807 1.8 1.8-.807 1.8-1.8 1.8-1.8-.807-1.8-1.8zm3.6 18c0 .993-.807 1.8-1.8 1.8s-1.8-.807-1.8-1.8.807-1.8 1.8-1.8 1.8.807 1.8 1.8zm10.2-16.2c-.993 0-1.8-.807-1.8-1.8s.807-1.8 1.8-1.8 1.8.807 1.8 1.8-.807 1.8-1.8 1.8z"/>
                                    </svg>
                                </div>
                                <div>
                                    {this.state.forks}
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        )
    }
}

export default Project;