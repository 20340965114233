import "./header.css"

function Header() {
    return (
        <div className={"header"}>
            <h1 id={"heading"}>
                <a href={"/"}>
                    Mika Meyer
                </a>
            </h1>
            <div className={"headerItems"}>
                <div className={"headerItem"} id={"github"}>
                    <a href={"https://github.com/thefiremike"}>
                        GitHub
                    </a>
                </div>
                <div className={"headerItem"} id={"linkedin"}>
                    <a href={"https://linkedin.com/in/mika-meyer"}>
                        LinkedIn
                    </a>
                </div>
                <div className={"headerItem"} id={"instagram"}>
                    <a href={"https://www.instagram.com/thefiremike/"}>
                        Instagram
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Header;